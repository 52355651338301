$amm-disabled: var(--amm-disabled);
$amm-gray-light: var(--amm-gray-light);
$amm-gray: var(--amm-gray);
$amm-green-dark: var(--amm-green-dark);
$amm-green: var(--amm-green);
$amm-light-gray: var(--amm-light-gray);
$amm-link-darken: var(--amm-link-darken);
$amm-link: var(--amm-link);
$amm-pink-light: var(--amm-pink-light);
$amm-pink: var(--amm-pink);
$amm-purple-dark: var(--amm-purple);
$amm-purple: var(--amm-purple);
$amm-red: var(--amm-red);
$amm-text-dark: var(--amm-text-dark);
$amm-text: var(--amm-text);
$box-shadow: var(--box-shadow);
$evaluation-green: var(--gree-evaluation);
$input-bg-color: var(--input-bg-color);
$menu-item-dark: var(--menu-item-dark);
$menu-item: var(--menu-item);
$seperator: var(--seperator);

$header-bg: var(--header-bg);
$header-dropdown: var(--header-dropdown);
$header-c: var(--header-c);

$footer-bg: var(--footer-bg);
$footer-c: var(--footer-c);

$sidebar-bg: var(--sidebar-bg);
$sidebar-c: var(--sidebar-c);
$modal-backdrop-opacity: 0.3;

$webcasts: var(--courses);
