@font-face {
  font-family: "Jost-Light";
  src: url("../fonts/somos/FuturaThin.ttf");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "Jost-Regular";
  src: url("../fonts/somos/FuturaBook.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Jost-Medium";
  src: url("../fonts/somos/FuturaStdHeavy.ttf");
  font-weight: 700;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "futura-pt-bold", sans-serif;
  font-weight: 700;
  font-style: normal;
}

strong {
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
}

input:not([type="checkbox"]),
.font-regular,
.fw-normal,
.fw-regular {
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
}

small,
.small,
.font-light,
.fw-light {
  font-family: "futura-pt", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.xsmall {
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.8em !important;
}
