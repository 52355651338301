@import "mixins";

.main-container {
  height: 100%;
  width: 100%;
  max-width: 100%;
  display: block;
}

// ! Sidebar layout styles check implementation
.sidebar-container {
  height: 100%;
  width: 100%;
  max-width: 100vw;
  display: flex;

  .sidebar-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .app-content {
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }
}

.row {
  width: 100% !important;
  margin: 0 !important;
}

.medium-container {
  width: 800px !important;
  max-width: 100% !important;
  margin-left: auto;
  margin-right: auto;
}

.full-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.min-container {
  width: 650px !important;
  max-width: 100% !important;
  margin-left: auto;
  margin-right: auto;
}

.content-container {
  width: 1100px !important;
  max-width: 100% !important;
  margin-left: auto;
  margin-right: auto;
}

.text-justify {
  text-align: justify !important;
}

.horizontally-container {
  display: flex !important;
  flex-direction: column !important;
}

.flex-1 {
  flex: 1;
}

.w-max {
  width: max-content !important;
}

.w-min {
  width: min-content !important;
}

.card * {
  transition: all 0.2s ease-in-out;
}

.card {
  transition: all 0.2s ease-in-out;
}

.btn {
  border-radius: var(--button-radius) !important;
  font-weight: 600 !important;
  font-weight: 600 !important;
  width: 100%;

  &:hover {
    box-shadow: none !important;
  }
}

.input-amm {
  border-radius: var(--input-radius) !important;
  text-align: left;
}
.text-underline {
  text-decoration: underline;
}
.no-decoration {
  text-decoration: none !important;
}
.no-border {
  border: none !important;
}

.border-radius-50 {
  border-radius: 50% !important;
}

.no-radius {
  border-radius: 0 !important;
}

.no-border-radius {
  border-radius: 0 !important;
}

.clickable {
  cursor: pointer !important;
}
.disabled-cursor {
  cursor: not-allowed !important;
}

.amm-login {
  background-image: url("/amm/images/home.jpg") !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  height: 100%;
}

.somos-login {
  background-image: linear-gradient(
      to bottom,
      rgba(30, 154, 169, 0.5),
      rgba(30, 154, 169, 0.5)
    ),
    url("/somos/images/somos_back_doctors.jpg");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  height: 100%;
}

.ammom-login {
  background-image: linear-gradient(
      to bottom,
      rgba(10, 52, 141, 0.7),
      rgba(10, 52, 141, 0.7)
    ),
    url("/ammom/images/ammom-login.jpg");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;

  aspect-ratio: 4/6;

  @include responsive(lg) {
    aspect-ratio: unset;
    height: 100%;
  }
}

.loading {
  cursor: progress !important;
  position: relative !important;
  overflow: hidden !important;
  max-height: 100vh;

  :not(.loading-spinner) {
    opacity: 0.45 !important;
  }

  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    opacity: 1 !important;

    * {
      opacity: 1 !important;
    }

    i {
      animation: spin 1.3s linear infinite;
    }
  }
}

@media (max-width: 768px) {
  .sidebar-container {
    flex-direction: column;
  }

  .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
  }
}
