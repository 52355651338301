@import "variables.scss";

// Text colors
.text-purple {
  color: $amm-purple !important;
}
.text-disabled {
  color: $amm-disabled !important;
}
.text-green-dark {
  color: $amm-green-dark !important;
}
// Text colors end

// Borders colors'
.border-amm-botom {
  border-bottom: 1px solid #000 !important;
}
.border-dark {
  border: 1.2px solid #000 !important;
  opacity: 1 !important;
}
// Border end

.form-check-input {
  border-color: black !important;
}

// Background colors
.bg-gray {
  background-color: $amm-gray !important;
}
.bg-light-gray {
  background-color: $amm-light-gray !important;
}
.bg-red {
  background-color: $amm-red !important;
}
// Background end

// Buttons
.btn-outline-primary:hover {
  color: #fff !important;
}
.btn-outline-dark:hover {
  color: $amm-text !important;
  background-color: $input-bg-color !important;
}
.btn-amm {
  box-shadow: 0px 3px 20px $box-shadow !important;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 3px 20px $box-shadow !important;
  }
}
.btn-primary {
  color: #fff !important;
}
// Buttons end

// CARDS
.boxshadow-amm {
  box-shadow: 0px 3px 6px #00000029;
}
.card-hoverable:hover {
  background-color: #f2f2f2 !important;
}
.card-selected {
  border: 1px solid $amm-pink !important;
  background-color: #f2f2f2 !important;

  .card-title {
    color: $amm-pink !important;
  }
}
// END CARDS

// LIST
ul li::marker {
  color: $amm-pink !important;
}
::ng-deep ul li::marker {
  color: $amm-pink !important;
}
// END LIST

// MODALS
.modal-header {
  color: rgb(239 238 239) !important;
  border: none !important;
}
.modal-body {
  padding: 0 !important;
}
// END MODALS

// FORM COLORS
.error-message {
  color: $amm-red;
  padding: 10px 0.5em;
  width: 100%;
}
.required {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.required:after {
  content: "*" !important;
  position: absolute;
  top: 10px;
  right: -10px;
  display: flex;
  align-items: center;
  color: $amm-pink !important;
}
input.is-invalid {
  border: 1px solid red !important;
}
.input-amm {
  background-color: $input-bg-color !important;
  color: $amm-text !important;
  border-color: $input-bg-color !important;
}
.form-select[readonly],
.form-control[readonly],
.form-control.textarea[readonly],
.form-check-input[readonly] {
  cursor: not-allowed !important;
  resize: none !important;

  &:focus {
    box-shadow: unset !important;
  }
}
.text-area {
  width: 100% !important;
  background-color: $input-bg-color !important;
  color: $amm-text !important;

  &:not(.is-invalid) {
    border: none !important;
  }

  &:focus {
    outline: 1px solid $amm-pink !important;
    opacity: 1 !important;
  }
}
.amm-link-default {
  color: $amm-link !important;
  cursor: pointer;
  text-decoration: underline !important;

  &:hover {
    color: $amm-link-darken;
  }
}
.amm-link {
  color: $amm-link !important;
  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    color: $amm-link-darken;
  }
}
// FORM END

// ng select package customization
::ng-deep .ng-select {
  border-radius: var(--input-radius) !important;
  background-color: $input-bg-color !important;
  width: 100% !important;
}
::ng-deep .ng-select-container {
  border-radius: inherit !important;
  background-color: $input-bg-color !important;
}
::ng-deep
  .ng-select.ng-select-focused:not(.ng-select-opened)
  > .ng-select-container {
  border-color: $amm-pink !important;
  box-shadow: 0 0 0 0 0.25em $amm-pink-light !important;
}
::ng-deep
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  background-color: $amm-pink-light !important;
}
::ng-deep .ng-option:hover {
  background-color: $amm-pink !important;
  color: #fff !important;
}
::ng-deep .ng-option-marked {
  background-color: $amm-pink-light !important;
}
