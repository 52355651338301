:root {
  --amm-disabled: #bab2b5;
  --amm-gray-light: #74636b;
  --amm-gray: #595457;
  --amm-green-dark: #52ad52;
  --amm-green: #25d366;
  --amm-light-gray: #efefef;
  --amm-link-darken: #1e9aa9;
  --amm-link: #fff;
  --amm-pink-light: #44aeb7;
  --amm-pink: #258f98;
  --amm-purple: #5e3189;
  --amm-red: #d53264;
  --amm-text-dark: #2f2a2d;
  --amm-text: #5a5457;
  --box-shadow: #00000065;
  --gree-evaluation: #a9bf02;
  --input-bg-color: #f7f5f6;
  --menu-item-dark: #148690;
  --menu-item: #1d7e86;
  --seperator: #595457;

  --courses: #08849b;

  --amm-table-pink: #258f98;
  --amm-table-blue: #679fc4;
  --amm-table-purple: #44aeb7;
  --amm-table-aqua: #07090a;
  --amm-table-aqua-light: #62acb5;
  --striped-color: rgb(247 245 246);

  --button-radius: 4px;
  --input-radius: 4px;

  --header-bg: #258f98;
  --header-dropdown: #258f98;
  --header-c: #fff;

  --footer-bg: #5e3189;
  --footer-c: #efeeee;

  --sidebar-bg: #f2f2f2;
  --sidebar-c: #928f91;
}
/* You can add global styles to this file, and also import other style files */
@import "assets/css/global";
@import "assets/css/table";
@import "assets/css/fonts.somos";
@import "assets/css/colors";
@import "assets/css/animations";
@import "assets/css/custom-colors.somos";

html,
body {
  margin: 0;
  padding: 0;
  color: var(--amm-text);
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: normal !important;
}

body {
  height: 100%;
  width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

router-outlet + * {
  animation: fade-out 0.55s;
}
/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
