.table-header th {
  background-color: #F0F0F0 !important;
  padding: 15px 10px !important;

  &:not(:last-child) {
    border-right: 1px solid #dddddd !important;
  }
}

table {
  font-family: arial, sans-serif !important;
  border-collapse: collapse !important;
  width: 100% !important;

  .btn {
    width: unset !important;
  }
}


td, th {
  align-items: center !important;
  padding: 1.3em 8px !important;
  text-align: center !important;
  border-bottom: 1px solid #dddddd !important;
}

.table-responsive {
  box-shadow: 0px 3px 6px #00000029 !important;
}

.table-line {
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;

  &:hover {
    background-color: #F0F0F0 !important;
  }
}
