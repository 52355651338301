// animation: jump 0.25s ease-in-out forwards;
@keyframes fade-out {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes jump {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
    visibility: visible;
  }
}

@keyframes hide {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
    visibility: hidden;
    height: 0px;
    margin: 0;
    padding: 0;
  }
}

@keyframes hideY {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
    visibility: hidden;
    height: 0px;
    margin: 0;
    padding: 0;
  }
}

@keyframes hideX {
  from {
    transform: scaleX(1);
  }

  to {
    transform: scaleX(0);
    visibility: hidden;
    height: 0px;
    margin: 0;
    padding: 0;
  }
}

@keyframes nexta {
  from {
    transform: translateX(-100%);
  } to {
    transform: translateX(0%);
  }
}

@keyframes preva {
  from {
    transform: translateX(100%);
  } to {
    transform: translateX(0%);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.show-data {
  animation: fade-out 0.35s linear forwards;
}

.hide-data {
  animation: fade-in 0.35s linear forwards;
}